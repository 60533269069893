<template>
    <div>
        <div class="ContentF">
            <div class="B01">

                <div class="B1">
                    <div v-if="this.name == 'barragan' || this.name == 'barraganService'" class="B1C1">
                        <img src="../assets/logo1_1.png">
                    </div>
                    <div v-else class="B1C1">
                        <img src="../assets/logo2F.png">
                    </div>
                    <div class="B1C2">
                        <p>LABORATORIO CLÍNICO BARRAGAN</p>
                    </div>
                </div>
                <div class="B2">
                    <div class="B2F1">
                        <p>Menú</p>
                    </div>
                    <div class="B2F2">
                        <ul v-if="this.name == 'barragan' || this.name == 'barraganService'">
                            <li><a href="/barragan">Inicio</a></li>
                            <li><a @click="redirect('barragan', 'nosotros')">Nosotros</a></li>
                            <li><a href="/barragan/servicios">Servicios</a></li>
                            <li><a @click="redirect('barragan', 'certificaciones')">Certificaciones</a></li>
                            <li><a @click="redirect('barragan', 'contacto')">Contacto</a></li>
                        </ul>
                        <ul v-else>
                            <li><a href="/laason">Inicio</a></li>
                            <li><a @click="redirect('laason', 'nosotros')">Nosotros</a></li>
                            <li><a href="/laason/servicios">Servicios</a></li>
                            <li><a @click="redirect('laason', 'acreditaciones')">Acreditaciones</a></li>
                            <li><a @click="redirect('laason', 'contacto')">Contacto</a></li>
                        </ul>

                    </div>
                </div>
                <div class="B3">
                    <div class="B3F1">
                        <p>Servicios</p>
                    </div>
                    <div class="B3F2">
                        <ul v-if="this.name == 'barragan' || this.name == 'barraganService'">
                            <li><a @click="redirect('barragan/servicios', 'analisis-clinicos')">Análisis clínicos</a>
                            </li>
                            <li><a @click="redirect('barragan/servicios', 'pruebas-de-antidoping')">Pruebas de
                                    Antidoping</a></li>
                            <li><a @click="redirect('barragan/servicios', 'filtros-sanitarios')">Filtros sanitarios para
                                    la manipulación de
                                    alimentos</a></li>
                            <li><a @click="redirect('barragan/servicios', 'pruebas-covid')">Pruebas Covid</a></li>
                        </ul>
                        <ul v-else>
                            <li><a @click="redirect('laason/servicios', 'analisis-microbiologico')">Análisis
                                    microbiológico</a></li>
                            <li><a @click="redirect('laason/servicios', 'analisis-fisioquimic-de-agua-potable')">Análisis
                                    fisicoquímicos</a></li>
                            <li><a @click="redirect('laason/servicios', 'etiquetado-nutrimental')">Etiquetado nutrimental México y FDA</a></li>
                            <li><a @click="redirect('laason/servicios', 'analisis-clinicos-para-alimentos')">Análisis clínicos</a></li>
                            <li><a @click="redirect('laason/servicios', 'otros-analisis')">Otros análisis</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="B02">
                <div class="B02F1">
                    <p>© 2024 por Laason Laboratorios</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'FooterSec',
    props: {
        name: {
            required: true
        }
    },

    methods: {
        redirect(pagina, direccion) {
            const existencia = document.getElementById(direccion);

            if (existencia) {
                window.location.href = `#${direccion}`
            } else {
                localStorage.setItem('direccion', direccion);
                window.location.href = `/${pagina}`
            }
        }
    },
}
</script>

<style scoped>
.ContentF {
    width: 100%;
    background-color: #2B415E;
    height: 147.7294685990338VW;
}

.B1 {
    width: 100%;
    height: 23.42995169082126VW;
    display: flex;
    border-bottom: 1px solid #516B8C;
    align-items: center;
}

.B1C1 {
    margin-left: 13.526570048309178VW;
}

.B1C1 img {
    width: auto;
    height: 12.077294685990339VW;
}

.B1C2 {
    height: 1vw;
    margin-left: 5.555555555555555VW;
}

.B1C2 p {
    color: white;
    font-size: 3.140096618357488VW;
    font-weight: 700;
}

.B2 {
    width: 100%;
    height: 27vw;
    text-align: -webkit-center;
    margin: 11.352657004830919VW 0vw;
}

.B2F1 {
    margin-left: -64vw;
}

.B2F1 p {
    font-size: 3.526570048309178VW;
    color: white;
}

.B2F2 {
    width: 73vw;
    height: 12vw;
}

.B2F2 ul {
    color: #548EBA;
    font-size: 2.898550724637681VW;
    columns: 2;
    text-align: left;
    line-height: 7.188405797101449VW;
    padding-left: 6vw;
}

.B2F2 a {
    color: #548EBA;
}

.B3 {
    width: 100%;
    height: 46vw;
    margin-bottom: 11vw;
    text-align: -webkit-center;
}

.B3F1 {
    margin-left: -57vw;
}

.B3F1 p {
    font-size: 3.526570048309178VW;
    color: white;
}

.B3F2 {
    width: 56vw;
    height: 38vw;
    margin-left: -30vw;
}

.B3F2 ul {
    color: #548EBA;
    font-size: 2.898550724637681VW;
    text-align: left;
    line-height: 8.091787439613526VW;
}

.B3F2 a {
    color: #548EBA;
}

.B02 {
    width: 100%;
    height: 17.14975845410628VW;
    text-align: center;
    padding-top: 7.971014492753622VW;
    border-top: 1px solid #516B8C;
    margin-left: 0vw;
}

.B02 p {
    font-size: 2.1739130434782608VW;
    font-weight: 700;
    color: white;
}

@media (min-width: 768px) {

    .ContentF {
        width: 100%;
        background-color: #2B415E;
        height: 21.5625VW;
    }

    .B01 {
        display: flex;
    }


    .B1 {
        width: 21vw;
        height: 17.135416666666668VW;
        align-items: center;
        justify-content: center;
        border-bottom: 0px solid #516B8C;
    }

    .B1C1 {
        margin-left: 0VW;
    }

    .B1C1 img {
        width: auto;
        height: 9.0625VW;
    }

    .B1C2 {
        display: none;
    }

    .B2 {
        width: 27vw;
        height: 7vw;
        text-align: -webkit-center;
        margin: 0vw;
        margin-top: 6.5VW;
    }

    .B2F1 {
        width: 28vw;
        text-align: left;
        margin-left: 0vw;
    }

    .B2F1 p {
        font-size: 0.7291666666666666VW;
    }

    .B2F2 {
        width: 16vw;
        height: 5vw;
        margin-left: -15vw;
    }

    .B2F2 ul {
        font-size: 0.625VW;
        -moz-columns: 2;
        columns: 2;
        line-height: 1.8083333333333333VW;
        padding-left: 3.2vw;
    }

    .B2F2 a {
        color: #548EBA;
    }

    .B3 {
        width: 31vw;
        height: 5vw;
        margin-bottom: 0vw;
        margin-top: 6.5VW;
    }

    .B3F1 {
        margin-left: 0vw;
        width: 26vw;
        text-align: left;
    }

    .B3F1 p {
        font-size: 0.7291666666666666VW;
    }

    .B3F2 {
        width: 31vw;
        height: 3vw;
        margin-left: 0vw;
    }

    .B3F2 ul {
        font-size: 0.625VW;
        line-height: 1.8083333333333333VW;
        columns: 2;
    }

    .B3F2 a {
        color: #548EBA;
    }


    .B02 {
        width: 88%;
        height: 4.947916666666666VW;
        padding-top: 2VW;
        margin-left: 7vw;
        border-top: 1px solid #516B8C;
    }

    .B02 p {
        font-size: 0.6770833333333334VW;
    }
}
</style>
